var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "main-section bg-white has-no-scroll" },
    [
      _c("main-section-header", {
        attrs: { title: _vm.title[_vm.currentCompany], "is-sticky": true },
      }),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("subtitle", { attrs: { subtitle: "설비 정보" } }),
          !_vm.isLoading
            ? [
                _c(
                  "ul",
                  {
                    staticClass: "detail-list",
                    class: { "factory-detail": _vm.isFactoryComp },
                  },
                  _vm._l(_vm.equipmentInfo, function (equipInfo, index) {
                    return _c(
                      "detail-list-row",
                      {
                        key: index,
                        attrs: {
                          info: equipInfo,
                          inputKey: equipInfo.key,
                          "input-value": _vm.equipmentData[equipInfo.key],
                          maxlength: equipInfo.maxlength,
                          placeholder: equipInfo.placeholder,
                          "dropdown-list": _vm.dropdownList[equipInfo.key],
                          "radio-label": _vm.isActiveRadioLabel,
                        },
                        on: {
                          setSelectedIdList: _vm.onSetSelectedIdList,
                          saveInputValue: _vm.onSaveEditValue,
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "label",
                            class: { "label-required": equipInfo.isRequired },
                            attrs: { slot: "label" },
                            slot: "label",
                          },
                          [_vm._v(" " + _vm._s(equipInfo.label) + " ")]
                        ),
                        equipInfo.key === "id"
                          ? _c(
                              "span",
                              {
                                staticClass: "contents",
                                attrs: { slot: "contents" },
                                slot: "contents",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("formatEQPCode")({
                                        type: _vm.currentCompany,
                                        eqpId: _vm.equipmentData["id"],
                                      })
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : equipInfo.key === "equipmentGroup"
                          ? _c(
                              "span",
                              {
                                staticClass: "contents",
                                attrs: { slot: "contents" },
                                slot: "contents",
                              },
                              [
                                _vm.equipmentData[equipInfo.key]
                                  ? _c(
                                      "span",
                                      { staticClass: "dropdown-data" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.equipmentData[equipInfo.key]
                                                .name
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _vm.dropdownList.hasOwnProperty(equipInfo.key)
                          ? _c(
                              "span",
                              {
                                staticClass: "contents",
                                class: {
                                  "sensor-width": equipInfo.key === "sensors",
                                },
                                attrs: { slot: "contents" },
                                slot: "contents",
                              },
                              [
                                equipInfo.key === "mainManagerUser"
                                  ? [
                                      _c(
                                        "span",
                                        { staticClass: "dropdown-data" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getManagerByPriority(1)
                                                  .userName || "-"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  : equipInfo.key === "subManagerUser"
                                  ? [
                                      _vm.getManagerByPriority(2)
                                        ? _c(
                                            "span",
                                            { staticClass: "dropdown-data" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getManagerByPriority(2)
                                                      .userName
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _c("span", [_vm._v("-")]),
                                    ]
                                  : [
                                      _vm.equipmentData[equipInfo.key].length
                                        ? _vm._l(
                                            _vm.equipmentData[equipInfo.key],
                                            function (equipGroup, index) {
                                              return _c(
                                                "span",
                                                {
                                                  key: index,
                                                  staticClass: "dropdown-data",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        equipGroup.name ||
                                                          equipGroup.userName
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          )
                                        : [_c("span", [_vm._v("-")])],
                                    ],
                              ],
                              2
                            )
                          : equipInfo.key === "isActive"
                          ? _c(
                              "span",
                              {
                                staticClass: "contents",
                                attrs: { slot: "contents" },
                                slot: "contents",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.equipmentData[equipInfo.key]
                                        ? "활성"
                                        : "비활성"
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : equipInfo.key === "qrCode"
                          ? _c(
                              "span",
                              {
                                staticClass: "contents",
                                attrs: { slot: "contents" },
                                slot: "contents",
                              },
                              [
                                _c("span", { staticClass: "qr-code" }, [
                                  _c("span", { staticClass: "mr-10" }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.equipmentData.qrImage,
                                          download:
                                            "QRCode_" + _vm.equipmentData.id,
                                        },
                                      },
                                      [_vm._v("다운로드")]
                                    ),
                                  ]),
                                  _c(
                                    "span",
                                    { on: { click: _vm.onStartPrint } },
                                    [_vm._v("인쇄하기")]
                                  ),
                                ]),
                              ]
                            )
                          : equipInfo.key === "createDT"
                          ? _c(
                              "span",
                              {
                                staticClass: "contents",
                                attrs: { slot: "contents" },
                                slot: "contents",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("localDateYYYYMMDD")(
                                        _vm.equipmentData[equipInfo.key]
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              {
                                staticClass: "contents",
                                attrs: { slot: "contents" },
                                slot: "contents",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.equipmentData[equipInfo.key] || "-"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                      ]
                    )
                  }),
                  1
                ),
                _vm.isManufacturerComp
                  ? [
                      _c("subtitle", { attrs: { subtitle: "납품 정보" } }),
                      _c(
                        "ul",
                        { staticClass: "detail-list" },
                        _vm._l(
                          _vm.deliveryEquipInfo,
                          function (deliveryInfo, index) {
                            return _c(
                              "detail-list-row",
                              {
                                key: index,
                                attrs: {
                                  info: deliveryInfo,
                                  inputKey: deliveryInfo.key,
                                  "input-value":
                                    _vm.equipmentData[deliveryInfo.key],
                                  maxlength: deliveryInfo.maxlength,
                                  placeholder: deliveryInfo.placeholder,
                                  "dropdown-list":
                                    _vm.dropdownList[deliveryInfo.key],
                                },
                                on: {
                                  setSelectedIdList: _vm.onSetSelectedIdList,
                                  saveInputValue: _vm.onSaveEditValue,
                                  openConfmsStatePopup: function ($event) {
                                    return _vm.togglePopup(
                                      "ConfirmPopup",
                                      "REJECTED_FACTORY"
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "label",
                                    class: {
                                      "label-required": deliveryInfo.isRequired,
                                    },
                                    attrs: { slot: "label" },
                                    slot: "label",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(deliveryInfo.label) + " "
                                    ),
                                  ]
                                ),
                                _vm.dropdownList.hasOwnProperty(
                                  deliveryInfo.key
                                )
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "contents",
                                        attrs: { slot: "contents" },
                                        slot: "contents",
                                      },
                                      [
                                        _vm.equipmentData[deliveryInfo.key]
                                          ? _c(
                                              "span",
                                              { staticClass: "dropdown-data" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.equipmentData[
                                                        deliveryInfo.key
                                                      ].name
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "contents",
                                        attrs: { slot: "contents" },
                                        slot: "contents",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.equipmentConfirmsStateName[
                                                _vm.equipmentData[
                                                  deliveryInfo.key
                                                ]
                                              ]
                                            ) +
                                            " "
                                        ),
                                        _vm.equipmentData
                                          .equipmentConfmsProcessDT
                                          ? [
                                              _vm._v(
                                                " (" +
                                                  _vm._s(
                                                    _vm._f("localDateYYYYMMDD")(
                                                      _vm.equipmentData
                                                        .equipmentConfmsProcessDT
                                                    )
                                                  ) +
                                                  ") "
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                              ]
                            )
                          }
                        ),
                        1
                      ),
                    ]
                  : _vm._e(),
                _c(
                  "list-bottom-button-box",
                  [
                    _vm.isSupervisorOrFactory
                      ? _c(
                          "button-basic",
                          {
                            attrs: {
                              slot: "button",
                              color: "gray-border-1",
                              size: "m",
                              width: 120,
                            },
                            on: { click: _vm.onShowDeleteEqpPopup },
                            slot: "button",
                          },
                          [_vm._v(" 설비 삭제 ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("confirm-popup", {
        attrs: {
          isShow: _vm.showedPopupName === "ConfirmPopup",
          contentsKey: _vm.popupContentsKey,
        },
        on: { close: _vm.onCloseConfirmPopup, confirm: _vm.onConfirmInPopup },
      }),
      _vm.equipmentData["qrCode"]
        ? _c("qr-print", {
            attrs: {
              "qr-size": "s",
              qrCodeIdText: _vm.equipmentData["qrCode"]["equipmentUuid"],
              isShowQrCodeId: true,
              isStartPrint: _vm.isStartPrint,
              logoSvg: _vm.qrLogoSvg,
              qrCodeId: JSON.stringify(_vm.equipmentData["qrCode"]),
              title: _vm._f("formatEQPCode")({
                type: _vm.currentCompany,
                eqpId: _vm.equipmentData["id"],
              }),
              subtitle: _vm.qrSubtitle,
            },
            on: { endPrint: _vm.onEndPrint },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }