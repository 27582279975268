<template>
  <section class="main-section bg-white has-no-scroll">
    <main-section-header :title="title[currentCompany]" :is-sticky="true" />
    <div class="container">
      <subtitle subtitle="설비 정보" />
      <template v-if="!isLoading">
        <ul class="detail-list" :class="{ 'factory-detail': isFactoryComp }">
          <detail-list-row
            v-for="(equipInfo, index) in equipmentInfo"
            :key="index"
            :info="equipInfo"
            :inputKey="equipInfo.key"
            :input-value="equipmentData[equipInfo.key]"
            :maxlength="equipInfo.maxlength"
            :placeholder="equipInfo.placeholder"
            :dropdown-list="dropdownList[equipInfo.key]"
            :radio-label="isActiveRadioLabel"
            @setSelectedIdList="onSetSelectedIdList"
            @saveInputValue="onSaveEditValue"
          >
            <span slot="label" class="label" :class="{ 'label-required': equipInfo.isRequired }">
              {{ equipInfo.label }}
            </span>
            <span slot="contents" class="contents" v-if="equipInfo.key === 'id'">
              {{ { type: currentCompany, eqpId: equipmentData['id'] } | formatEQPCode }}
            </span>
            <span slot="contents" class="contents" v-else-if="equipInfo.key === 'equipmentGroup'">
              <span class="dropdown-data" v-if="equipmentData[equipInfo.key]">
                {{ equipmentData[equipInfo.key].name }}
              </span>
            </span>
            <span
              slot="contents"
              class="contents"
              v-else-if="dropdownList.hasOwnProperty(equipInfo.key)"
              :class="{ 'sensor-width': equipInfo.key === 'sensors' }"
            >
              <template v-if="equipInfo.key === 'mainManagerUser'">
                <span class="dropdown-data">
                  {{ getManagerByPriority(1).userName || '-' }}
                </span>
              </template>
              <template v-else-if="equipInfo.key === 'subManagerUser'">
                <span class="dropdown-data" v-if="getManagerByPriority(2)">
                  {{ getManagerByPriority(2).userName }}
                </span>
                <span v-else>-</span>
              </template>
              <template v-else>
                <template v-if="equipmentData[equipInfo.key].length">
                  <span v-for="(equipGroup, index) in equipmentData[equipInfo.key]" :key="index" class="dropdown-data">
                    {{ equipGroup.name || equipGroup.userName }}
                  </span>
                </template>
                <template v-else>
                  <span>-</span>
                </template>
              </template>
            </span>
            <span slot="contents" class="contents" v-else-if="equipInfo.key === 'isActive'">
              {{ equipmentData[equipInfo.key] ? '활성' : '비활성' }}
            </span>
            <span slot="contents" class="contents" v-else-if="equipInfo.key === 'qrCode'">
              <span class="qr-code">
                <span class="mr-10">
                  <a :href="equipmentData.qrImage" :download="`QRCode_${equipmentData.id}`">다운로드</a>
                </span>
                <span @click="onStartPrint">인쇄하기</span>
              </span>
            </span>
            <span slot="contents" class="contents" v-else-if="equipInfo.key === 'createDT'">
              {{ equipmentData[equipInfo.key] | localDateYYYYMMDD }}
            </span>
            <span slot="contents" class="contents" v-else>
              {{ equipmentData[equipInfo.key] || '-' }}
            </span>
          </detail-list-row>
        </ul>
        <template v-if="isManufacturerComp">
          <subtitle subtitle="납품 정보" />
          <ul class="detail-list">
            <detail-list-row
              v-for="(deliveryInfo, index) in deliveryEquipInfo"
              :key="index"
              :info="deliveryInfo"
              :inputKey="deliveryInfo.key"
              :input-value="equipmentData[deliveryInfo.key]"
              :maxlength="deliveryInfo.maxlength"
              :placeholder="deliveryInfo.placeholder"
              :dropdown-list="dropdownList[deliveryInfo.key]"
              @setSelectedIdList="onSetSelectedIdList"
              @saveInputValue="onSaveEditValue"
              @openConfmsStatePopup="togglePopup('ConfirmPopup', 'REJECTED_FACTORY')"
            >
              <span slot="label" class="label" :class="{ 'label-required': deliveryInfo.isRequired }">
                {{ deliveryInfo.label }}
              </span>
              <span slot="contents" class="contents" v-if="dropdownList.hasOwnProperty(deliveryInfo.key)">
                <span class="dropdown-data" v-if="equipmentData[deliveryInfo.key]">
                  {{ equipmentData[deliveryInfo.key].name }}
                </span>
              </span>
              <span slot="contents" class="contents" v-else>
                {{ equipmentConfirmsStateName[equipmentData[deliveryInfo.key]] }}
                <template v-if="equipmentData.equipmentConfmsProcessDT">
                  ({{ equipmentData.equipmentConfmsProcessDT | localDateYYYYMMDD }})
                </template>
              </span>
            </detail-list-row>
          </ul>
        </template>
        <list-bottom-button-box>
          <button-basic
            slot="button"
            color="gray-border-1"
            size="m"
            :width="120"
            @click="onShowDeleteEqpPopup"
            v-if="isSupervisorOrFactory"
          >
            설비 삭제
          </button-basic>
        </list-bottom-button-box>
      </template>
    </div>
    <confirm-popup
      :isShow="showedPopupName === 'ConfirmPopup'"
      :contentsKey="popupContentsKey"
      @close="onCloseConfirmPopup"
      @confirm="onConfirmInPopup"
    />
    <qr-print
      v-if="equipmentData['qrCode']"
      qr-size="s"
      :qrCodeIdText="equipmentData['qrCode']['equipmentUuid']"
      :isShowQrCodeId="true"
      :isStartPrint="isStartPrint"
      :logoSvg="qrLogoSvg"
      :qrCodeId="JSON.stringify(equipmentData['qrCode'])"
      :title="{ type: currentCompany, eqpId: equipmentData['id'] } | formatEQPCode"
      :subtitle="qrSubtitle"
      @endPrint="onEndPrint"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import MainSectionHeader from '@/component/mainSectionHeader/MainSectionHeader';
import Subtitle from '@/component/subtitle/Subtitle';
import DetailListRow from '@/component/detailListRow/DetailListRow';
import ListBottomButtonBox from '@/component/list/ListBottomButtonBox';
import EqpManufacturerInfo from './EquipmentDetailData/ManufacturerInfo.json';
import EqpFactoryInfo from './EquipmentDetailData/FactoryInfo.json';
import EqpManufacturerDeliveryInfo from './EquipmentDetailData/ManufacturerDeliveryInfo.json';
import ConfirmPopup from '@/component/popup/confirmPopup/ConfirmPopup';
import { QRLOGO } from '@/const/qrCodeSvg.js';
import getManagerName from '@/asset/js/getManagerName';

export default {
  name: 'EquipmentDetail',
  props: [],
  data() {
    return {
      title: {
        M: '설비 상세정보',
        F: '자체설비 상세정보',
      },
      equipmentInfo: '',
      deliveryEquipInfo: EqpManufacturerDeliveryInfo,
      equipmentId: this.$route.params.eId,
      equipmentData: {
        modelName: '',
        serialNumber: null,
        equipmentName: '',
        equipmentGroupId: 0,
        equipmentManagerIds: [],
        mainManagerUserId: 0,
        subManagerUserId: 0,
        sensorIds: [],
        factoryId: 0,
        equipmentGroup: {},
        equipmentManagers: [],
        sensors: [],
        mainManagerUser: {},
        subManagerUser: {},
      },
      dropdownList: {
        equipmentGroup: [],
        equipmentManagers: [],
        mainManagerUser: [],
        subManagerUser: [],
        sensors: [],
        factoryCompany: [],
      },
      isActiveRadioLabel: [
        {
          value: true,
          label: '활성',
        },
        {
          value: false,
          label: '비활성',
        },
      ],
      equipmentConfirmsStateName: {
        WAITING: '승인 대기',
        APPROVED: '승인',
        REJECTED: '반려',
      },
      showedPopupName: '',
      popupContentsKey: '',
      isStartPrint: false,
      qrLogoSvg: QRLOGO,
      qrSubtitle: '',
      isLoading: true,
      mainManagerUserList: [],
      subManagerUserList: [],
    };
  },
  computed: {
    ...mapState('users', ['myInfo', 'currentCompany']),
    ...mapGetters('users', ['isSupervisorOrFactory', 'isSupervisor', 'isManufacturerComp', 'isFactoryComp']),
  },
  created() {
    this.initRenderData();
  },
  mounted() {},
  watch: {
    $route() {
      this.initRenderData();
    },
  },
  methods: {
    ...mapActions('manufacturerEquipments', [
      'GET_MANUFACTURER_EQP',
      'GET_MANUFACTURER_FACTORY_NAME',
      'PUT_MANUFACTURER_EQP',
      'DELETE_MANUFACTURER_EQP',
    ]),
    ...mapActions('factoryEquipments', ['DELETE_FACTORY_EQP']),
    ...mapActions('factorySelfEquipments', ['GET_FACTORY_EQP_SELF', 'PUT_FACTORY_EQP_SELF']),
    ...mapActions('manufacturerEqpGroups', ['GET_MANUFACTURER_EQP_GROUPS']),
    ...mapActions('factoryEqpGroups', ['GET_FACTORY_EQP_GROUPS_SELF']),
    ...mapActions('manufacturerUsers', ['GET_MANUFACTURER_USER_NAME']),
    ...mapActions('factoryUsers', ['GET_FACTORY_USER_NAME']),
    ...mapActions('manufacturerSensors', ['GET_MANUFACTURER_SENSOR_MODEL']),
    ...mapActions('factorySensors', ['GET_FACTORY_SENSOR_MODEL']),
    initRenderData() {
      switch (this.currentCompany) {
        case 'M':
          this.equipmentInfo = EqpManufacturerInfo;
          this.getManufacturerEqp(this.equipmentId);
          if (this.isSupervisor) {
            this.getEquipmentGroupList();
            this.getUserNameList();
            this.getSensorNameList();
            this.getFactoryNameList();
          }
          break;
        case 'F':
          this.equipmentInfo = EqpFactoryInfo;
          this.getFactoryEqpSelf(this.equipmentId);
          this.getSelfEquipmentGroupList();
          this.getSelfUserNameList();
          this.getSelfSensorNameList();
          break;
      }
    },
    initDataForEdit() {
      //도입, 설비 공통
      this.equipmentData.equipmentGroupId = this.equipmentData.equipmentGroup.id;
      this.equipmentData.sensorIds = [];
      for (let i = 0; i < this.equipmentData.sensors.length; i++) {
        this.equipmentData.sensorIds.push(this.equipmentData.sensors[i].id);
      }
      this.qrSubtitle = this.equipmentData.modelName;
      switch (this.currentCompany) {
        case 'M':
          this.equipmentData.factoryId = this.equipmentData.factoryCompany.id;
          this.equipmentData.equipmentManagerIds = [];
          for (let i = 0; i < this.equipmentData.equipmentManagers.length; i++) {
            this.equipmentData.equipmentManagerIds.push(this.equipmentData.equipmentManagers[i].id);
          }
          if (this.equipmentData.equipmentConfmsState === 'APPROVED') {
            this.deliveryEquipInfo[0].isPossibleEdit = false;
          } else {
            this.deliveryEquipInfo[0].isPossibleEdit = true;
          }
          break;
        case 'F':
          this.equipmentData.mainManagerUser = this.getManagerByPriority(1);
          this.equipmentData.subManagerUser = this.getManagerByPriority(2);
          this.equipmentData.mainManagerUserId = this.getManagerByPriority(1).id;
          this.equipmentData.subManagerUserId = this.getManagerByPriority(2) && this.getManagerByPriority(2).id;
          this.dropdownList.mainManagerUser = this.filteredDropdownList(
            this.mainManagerUserList,
            (this.equipmentData.subManagerUser && this.equipmentData.subManagerUser.userName) || '',
          );
          this.dropdownList.subManagerUser = this.filteredDropdownList(
            this.subManagerUserList,
            (this.equipmentData.mainManagerUser && this.equipmentData.mainManagerUser.userName) || '',
          );
      }
    },
    //설비
    async getManufacturerEqp(eqpId) {
      try {
        this.isLoading = true;
        this.equipmentData = await this.GET_MANUFACTURER_EQP(eqpId);
        this.initDataForEdit();
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      } finally {
        this.isLoading = false;
      }
    },
    async getEquipmentGroupList() {
      try {
        const equipmentGroups = await this.GET_MANUFACTURER_EQP_GROUPS(['', '']);
        this.dropdownList.equipmentGroup = equipmentGroups;
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      }
    },
    async getUserNameList() {
      try {
        const userNameList = await this.GET_MANUFACTURER_USER_NAME();
        this.dropdownList.equipmentManagers = userNameList;
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      }
    },
    async getSensorNameList() {
      try {
        const sensorNameList = await this.GET_MANUFACTURER_SENSOR_MODEL();
        this.dropdownList.sensors = sensorNameList;
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      }
    },
    async getFactoryNameList() {
      try {
        const factoryNameList = await this.GET_MANUFACTURER_FACTORY_NAME();
        this.dropdownList.factoryCompany = factoryNameList;
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      }
    },
    //도입
    async getFactoryEqpSelf(eqpId) {
      try {
        this.isLoading = true;
        this.equipmentData = await this.GET_FACTORY_EQP_SELF(eqpId);
        this.isLoading = true;
        this.initDataForEdit();
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      } finally {
        this.isLoading = false;
      }
    },
    async getSelfEquipmentGroupList() {
      try {
        const equipmentGroups = await this.GET_FACTORY_EQP_GROUPS_SELF();
        this.dropdownList.equipmentGroup = equipmentGroups;
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      }
    },
    async getSelfUserNameList() {
      try {
        const userNameList = await this.GET_FACTORY_USER_NAME();
        this.mainManagerUserList = userNameList;
        this.subManagerUserList = userNameList;
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      }
    },
    async getSelfSensorNameList() {
      try {
        const sensorNameList = await this.GET_FACTORY_SENSOR_MODEL();
        this.dropdownList.sensors = sensorNameList;
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      }
    },
    async onSaveEditValue(inputKey, changedValue) {
      this.equipmentData[inputKey] = changedValue;
      if (inputKey === 'factoryCompany') {
        this.togglePopup('ConfirmPopup', 'WAITING_FACTORY');
      } else {
        switch (this.currentCompany) {
          case 'M':
            await this.PUT_MANUFACTURER_EQP([this.equipmentId, this.equipmentData]);
            await this.getManufacturerEqp(this.equipmentId);
            break;
          case 'F':
            await this.PUT_FACTORY_EQP_SELF([this.equipmentId, this.equipmentData]);
            await this.getFactoryEqpSelf(this.equipmentId);
            break;
        }
      }
    },
    async onSaveEquipment() {
      switch (this.currentCompany) {
        case 'M':
          await this.POST_MANUFACTURER_EQP(this.equipmentData);
          break;
        case 'F':
          await this.POST_FACTORY_EQP_SELF(this.equipmentData);
          break;
      }
      this.onMoveEquipmentList();
    },
    onMoveEquipmentList() {
      this.$router.push(`/equipments`).catch((err) => err);
    },
    onSetSelectedIdList(contentIdList, selectedName, id) {
      switch (id) {
        case 'equipmentGroup':
          this.equipmentData.equipmentGroupId = contentIdList[0];
          break;
        case 'equipmentManagers':
          this.equipmentData.equipmentManagerIds = contentIdList;
          break;
        case 'mainManagerUser':
          this.equipmentData.mainManagerUserId = contentIdList[0];
          if (selectedName) {
            this.dropdownList.subManagerUser = this.filteredDropdownList(this.subManagerUserList, selectedName);
          }
          break;
        case 'subManagerUser':
          this.equipmentData.subManagerUserId = contentIdList[0];
          if (selectedName) {
            this.dropdownList.mainManagerUser = this.filteredDropdownList(this.mainManagerUserList, selectedName);
          }
          break;
        case 'sensors':
          this.equipmentData.sensorIds = contentIdList;
          break;
        case 'factoryCompany':
          this.equipmentData.factoryId = contentIdList[0];
      }
    },
    togglePopup(popupName, popupContentsKey) {
      this.showedPopupName = popupName || '';
      this.popupContentsKey = popupContentsKey || '';
    },
    async onConfirmInPopup() {
      switch (this.popupContentsKey) {
        case 'WAITING_FACTORY':
          await this.PUT_MANUFACTURER_EQP([this.equipmentId, this.equipmentData]);
          await this.getManufacturerEqp(this.equipmentId);
          this.togglePopup();
          break;
        case 'REJECTED_FACTORY':
          this.equipmentData.isReapprove = true;
          await this.PUT_MANUFACTURER_EQP([this.equipmentId, this.equipmentData]);
          await this.getFactoryEqpSelf(this.equipmentId);
          this.togglePopup();
          break;
        case 'DELETE_MANUFACTURER_EQUIP':
          await this.DELETE_MANUFACTURER_EQP(this.equipmentId);
          this.togglePopup('ConfirmPopup', 'DELETE_EQUIP_SUCCESS');
          break;
        case 'DELETE_FACTORY_EQUIP':
          await this.DELETE_FACTORY_EQP(this.equipmentId);
          this.togglePopup('ConfirmPopup', 'DELETE_EQUIP_SUCCESS');
          break;
      }
    },
    onCloseConfirmPopup() {
      switch (this.popupContentsKey) {
        case 'DELETE_EQUIP_SUCCESS':
          this.togglePopup();
          this.$router.push(`/equipments`).catch((err) => err);
          break;
        default:
          this.togglePopup();
          break;
      }
    },
    onShowDeleteEqpPopup() {
      this.popupContentsKey = this.currentCompany === 'M' ? 'DELETE_MANUFACTURER_EQUIP' : 'DELETE_FACTORY_EQUIP';
      this.togglePopup('ConfirmPopup', this.popupContentsKey);
    },
    onStartPrint() {
      this.isStartPrint = true;
    },
    onEndPrint() {
      this.isStartPrint = false;
    },
    getManagerByPriority(priority) {
      return getManagerName(this.equipmentData.factoryEquipmentManagers, priority);
    },
    filteredDropdownList(list, selectedName) {
      return list.filter((managerData) => managerData.name !== selectedName);
    },
  },
  components: { Subtitle, DetailListRow, MainSectionHeader, ListBottomButtonBox, ConfirmPopup },
};
</script>

<style scoped lang="scss">
@import 'EquipmentDetail';
</style>
